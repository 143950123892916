let baseUrl = '';
let api_v = '';
// 测试环境
baseUrl = 'https://czhl.rongtell.com';
api_v = '/api/Zhjinguo/'
// 正式环境
// baseURL = '';

module.exports = {
	baseUrl: baseUrl,
	api_v:api_v,
}