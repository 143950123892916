import {post,get} from "./request"
let api = {}

// 智慧巾帼妇女之家 成员列表
/* 
  *param:limit page is_hot(1为首页  0为其他页面)
 */
api.getwomenshomeList = function(param){
  return get('/api/Zhjinguo/women_hourse_list',param)
}

// 文章
/* 
 *param:cid limit page
 * 能量家园文章：cid=27为物资采购制度 cid=28为积分兑换制度 cid=2为年度计划
 * 妇女之家文章：cid=23为妇联轮值工作制度 cid=24为结对帮扶工作制度
 */
api.getarticleList = function(param){
	return get('/api/Zhjinguo/article_list',param)
}

// 示范村基本情况
/* 
 *village_id  村id 默认不传为统计所有村
 */
api.getvillageInfo = function(param){
	return get('/api/Zhjinguo/village_info',param)
}

//活动开展完成情况

api.activityInfo = function(param){
	return get('/api/Zhjinguo/activity_show_cate',param)
}

// 最美新农人
/* 
 *is_hot
 *limit
 *page
 */
api.getnewFarmers=function(param){
	return get('/api/Zhjinguo/beautiful_farmer',param)
}

// 最美新农人详情 id
api.getnewFarmersDetail=(param)=>{
	return get('/api/Zhjinguo/beautiful_farmer_detail',param)
}

// 志愿服务组织
api.volunteerList = (param)=>{
	return get('/api/Zhjinguo/zuzhi_service_list',param)
}

// 活动展示
api.activityShow = (param)=>{
	return get('/api/Zhjinguo/activity_show_list',param)
}

// 家园奖励公示栏目

api.rewardcateList = (param)=>{
	return get('/api/Zhjinguo/reward_hourse_cate',param)
}

// 家园奖励公示栏目

api.rewardList = (param)=>{
	return get('/api/Zhjinguo/reward_hourse',param)
}

// 巾帼产业项目
api.industryList = (param)=>{
	return get('/api/Zhjinguo/user_jinguo_chanye',param)
}

// 巾帼产业项目详情
api.industryDetail = (param)=>{
	return get('/api/Zhjinguo/user_jinguo_chanye_detail',param)
}

api.trainingList = (param)=>{
	return get('/api/Zhjinguo/user_jinguo_peixun',param)
}

// 巾帼产业项目详情
api.trainingDetail = (param)=>{
	return get('/api/Zhjinguo/user_jinguo_peixun_detail',param)
}

// 获取详情
api.getDetail = (param)=>{
	return get('/api/Zhjinguo/article_detail',param)
}


// 组织架构
api.getuserFrameword = (param)=>{
	return get('/api/Zhjinguo/user_framework',param)
}
export default api

