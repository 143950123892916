const routes = [
    {
      // 页面逻辑
      path: '/',
      name: 'index',
      component: () => import('@/view/index')
    },
	{
	  // 能量家园
	  path: '/energyHome',
	  name: 'energyHome',
	  component: () => import('@/view/energyHome')
	},
	{
	  // 家庭驿站
	  path: '/homeStation',
	  name: 'homeStation',
	  component: () => import('@/view/homeStation')
	},
	{
	  // 普法之窗
	  path: '/popularizeLaw',
	  name: 'popularizeLaw',
	  component: () => import('@/view/popularizeLaw')
	},
	{
	  // 妇女之家
	  path: '/wonmensHome',
	  name: 'wonmensHome',
	  component: () => import('@/view/wonmensHome')
	},
	{
	  // 巾帼风采
	  path: '/wonmensMien',
	  name: 'wonmensMien',
	  component: () => import('@/view/wonmensMien')
	},
	{
	  // 文章详情
	  path: '/detail',
	  name: 'detail',
	  component: () => import('@/view/detail')
	},
	{
	  // 成员介绍详情
	  path: '/memberDetail',
	  name: 'memberDetail',
	  component: () => import('@/view/memberDetail')
	},
	{
	  // 最美新农人详情
	  path: '/farmerDetail',
	  name: 'farmerDetail',
	  component: () => import('@/view/farmerDetail')
	},
	{
	  // 产业详情
	  path: '/industryDetail',
	  name: 'industryDetail',
	  component: () => import('@/view/industryDetail')
	},
	{
	  // 技能培训详情
	  path: '/trainingDetail',
	  name: 'trainingDetail',
	  component: () => import('@/view/trainingDetail')
	},
	{
	  // 技组织结构图详情
	  path: '/treeDetail',
	  name: 'treeDetail',
	  component: () => import('@/view/treeDetail')
	}
  ];

export default routes
