<template>
	<!-- 标题 -->
	<div class="acea-row row-middle row-between title-box">
		<div class="acea-row row-middle row-between tabs-box">
			<div class="tab-item" :class="[tabOn==item.id?'tab-on-l':'tab-l']" v-for="(item,index) in tabsLeft" :key="index" @click="toRoute(item)" >{{item.name}}</div>
		</div>
		<div class="acea-row acea-row-nowrap row-middle row-center title line1">
			<img src="@/assets/images/logo.png" alt=""><span>{{title}}</span>
		</div>
		<div class="acea-row row-middle row-between tabs-box">
			<div class="tab-item" :class="[tabOn==item.id?'tab-on-r':'tab-r']" v-for="(item,index) in tabsRight" :key="index" @click="toRoute(item)" >{{item.name}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'the-header',
		data(){
			return{
				title : '湖南省郴州市智慧巾帼',
				tabsLeft:[{id:1,name:'首页',path:'/'},{id:2,name:'妇女之家',path:'/wonmensHome'},{id:3,name:'能量家园',path:'/energyHome'}],
				tabsRight:[{id:4,name:'巾帼风采',path:'/wonmensMien'},{id:5,name:'家庭驿站',path:'/homeStation'},{id:6,name:'普法之窗',path:'/popularizeLaw'}],
				tabOn:1
			}
		},
		mounted() {
			this.$router.replace('/')
		},
		methods:{
			toRoute(item){
				// console.log(item)
				this.tabOn = item.id
				this.$router.replace(item.path)
				// router.push({path:item.path})
			}
		}
	}
</script>

<style scoped lang="scss">
	/* 标题开始 */
	.title-box{
		width: 100%;
		height: 80px;
	}
	.title{
		width: 33%;
		height: 100%;
		background: url('@/assets/images/title_bg.png')no-repeat;
		background-size: 100% 100%;
		font-size: 32px;
		font-weight: bold;
		color: #ffffff;
		/* padding: 0 55px; */
	}
	.title img{
		display: block;
		margin: 0 20px 0 0px;
		width: 52px;
		height: 52px;
	}
	
	.tab-item{
		width: 30%;
		height: 40px;
		text-align: center;
		line-height: 40px;
	}
	.tab-l{
		color: #dc137b;
		background: url('@/assets/images/tab_bg.png')no-repeat;
		background-size: 100% 100%;
	}
	.tab-on-l{
		color: #ffffff;
		background: url('@/assets/images/tab_on_bg.png')no-repeat;
		background-size: 100% 100%;
	}
	.tab-r{
		color: #dc137b;
		position: relative;
		z-index: 2;
	}
	.tab-r:before{
		display: block;
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: url('@/assets/images/tab_bg.png')no-repeat;
		background-size: 100% 100%;
		transform: rotateX(180deg);
		z-index: -1;
	}
	.tab-on-r{
		color: #ffffff;
		position: relative;
		z-index: 2;
	}
	.tab-on-r:before{
		display: block;
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: url('@/assets/images/tab_on_bg.png')no-repeat;
		background-size: 100% 100%;
		transform: rotateX(180deg);
		z-index: -1;
	}
	.tabs-box{
		width: 33%;
		height: 80px;
		padding-top: 10px;
	}
	
	/* 标题结束 */
</style>