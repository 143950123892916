<template>
	<!-- <ScreenAdapter> -->
		<div class="content">
			<div class="acea-row row-middle row-center top-title">
				<img src="@/assets/images/top_icon_01.png" alt="">
				<div>中国移动数字乡村平台</div>
				<img src="@/assets/images/top_icon_01.png" alt="">
			</div>
			<div class="content-c">
				<!-- 标题 -->
				<TheHeader></TheHeader>
				<!-- 内容 -->
				<!-- <keep-alive> -->
					<router-view></router-view>
				<!-- </keep-alive> -->
			</div>
		</div>
	<!-- </ScreenAdapter> -->
</template>

<script>
	import ScreenAdapter from '@/components/ScreenAdapter.vue'
	import TheHeader from '@/components/TheHeader.vue'
	
	export default {
		name: 'App',
		components: {
			TheHeader,
			ScreenAdapter
		},
	}
</script>

<style scoped>
	@import "@/assets/css/common.css";
	.content{
		width: 100vw;
		height: 100vh;
		background: #ffecf6 url('@/assets/images/bg_04.png')no-repeat;
		background-size: 100% 100%;
		padding-top: 1px;
		position: relative;
	}
	.content-c{
		width: calc(100vw - 152px);
		height: calc(100vh - 122px);
		margin: 62px 71px 60px 81px;
		/* background: red; */
	}
	.top-title{
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		padding: 0 10px;
		color: #dc137b;
		font-size: 26px;
		font-weight: bold;
		z-index: 3;
	}
	.top-title img{
		width: 174px;
		height: 20px;
		margin: 0 4px;
	}
	.top-title img:last-child{
		transform: rotateY(180deg);
	}
	
	
	.view-box{
		width: 100%;
		height: calc(100% - 80px);
		background-color: #dc137b;
	}
</style>
