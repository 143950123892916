import Vue from 'vue'
import VueRouter from "vue-router";
import App from './App.vue'
//6.引入导出的路由
import router from './router/index'
import api from './common/request/api'
Vue.prototype.$api = api
Vue.config.productionTip = false

new Vue({
	router,
  render: h => h(App),
}).$mount('#app')
